export function compareTermCodes(a, b) {
  const TERMS_LOOKUP = ["spring", "summer", "fall", "winter"];
  const [yearA, termA] = a.split("-");
  const [yearB, termB] = b.split("-");

  if (parseInt(yearA) !== parseInt(yearB)) {
    return parseInt(yearA) - parseInt(yearB);
  }

  return TERMS_LOOKUP.indexOf(termA) - TERMS_LOOKUP.indexOf(termB);
}

export function sortTerms(terms) {
  return terms.sort(compareTermCodes);
}

export function totalEnrollment(sections) {
  let total = 0;

  sections.forEach((section) => {
    total += section.gradeDistribution.reduce((a, b) => a + b, 0);
  });

  return total;
}

export function averageByKey(list, key) {
  if (list && list.length > 0) {
    return (
      list.map((item) => item[key]).reduce((a, b) => a + b, 0) / list.length
    );
  }

  return null;
}
