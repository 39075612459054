import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../style/RegisterPage.css";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

async function activateUser(email, confirmationCode) {
  return fetch(`${apiUrl}/user/activate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      confirmationCode: confirmationCode,
    }),
  }).then((response) => {
    if (response.status !== 200) {
      console.log("Could not activate user");
    }
    return response.json();
  });
}

function ActivationPage(props) {
  const params = useParams();

  const [email, setEmail] = useState(params.email ? params.email : "");
  const [confCode, setConfCode] = useState(
    params.confirmationCode ? params.confirmationCode : ""
  );
  const [activated, setActivated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);

    activateUser(email, confCode).then((response) => {
      setActivated(response);
    });
  };

  console.log(props);

  return (
    <div className="rg-register-wrapper">
      <div>
        <h1>Activate Account</h1>
        {activated ? (
          <div>
            <p>
              Your account has been activated. You can now{" "}
              <Link to="/login">login</Link>.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="rg-input-container">
              <p>Email: </p>
              <input
                type="text"
                name="email"
                onChange={(e) => setEmail(`${e.target.value}@illinois.edu`)}
                required
                value={email.includes("@") ? email.split("@")[0] : ""}
              />
              <label> @ illinois.edu</label>
            </div>
            <div className="rg-input-container">
              <p>Activation Code: </p>
              <input
                type="text"
                name="activation"
                onChange={(e) => setConfCode(e.target.value)}
                required
                value={confCode}
              />
            </div>
            <div className="rg-button-container">
              <button type="submit">Activate</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default ActivationPage;
