import axios from "axios";
import "../style/ReviewsCard.css";
import { useState } from "react";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

const api = axios.create({
  baseURL: `${apiUrl}/review`,
});

function ReviewsCard(props) {
  const { reviews } = props;
  return (
    <div className="reviews-card">
      <h2>Reviews</h2>
      {reviews.map((review, i) => (
        <div key={`review-${i}`}>
          <p>
            {review.nickname} for{" "}
            {review.term[0].toUpperCase() + review.term.slice(1)} {review.year}
          </p>
          <p>{review.content}</p>
          <p className="review-text">{review.workload} hrs/wk</p>
          <p className="review-text">Difficulty: {review.difficulty}</p>
          <p className="review-text">Rating: {review.rating}</p>
          <hr></hr>
        </div>
      ))}
    </div>
  );
}

export default ReviewsCard;
