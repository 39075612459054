import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../style/ConfirmResetPage.css";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

async function confirmResetPassword(credentials) {
  return fetch(`${apiUrl}/user/confirmresetpassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((response) => {
    if (response.status !== 200) {
      throw Error("Fail to reset password");
    }
    return response.json();
  });
}

function ConfirmResetPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [confirmationCode, setConfirmationCode] = useState();
  const [newPassword, setNewPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await confirmResetPassword({
      email,
      confirmationCode,
      newPassword,
    })
      .then((res) => {
        alert("Successfully reset password !");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        alert("Confirmation Code is not right, please enter again");
      });
  };

  return (
    <div className="password-wrapper">
      <div className="confirm-wrapper">
        <h1>Reset Password - Confirm your email</h1>
        <p class="reset-msg">
          Please enter the confirmation code emailed to you <br />
          to complete the password reset process.
          <br />
        </p>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div className="input-item">
              <label>Email: </label>
              <input
                type="text"
                name="email"
                onChange={(e) => setEmail(`${e.target.value}@illinois.edu`)}
                required
              />
              <label id="domainName">@ illinois.edu</label>
            </div>
            <div className="input-item">
              <label>Confirmation code: </label>
              <input
                type="text"
                name="confirmationCode"
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
              />
            </div>
            <div className="input-item">
              <label>New Password: </label>
              <input
                type="password"
                name="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div class="button-container">
            <button type="submit">Complete registration</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfirmResetPage;
