import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../style/LoginPage.css";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

async function loginUser(credentials) {
  return fetch(`${apiUrl}/user/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((res) => {
    if (res.status !== 200) {
      console.log(res.status);
      throw Error("Fail to log in");
    }

    return res.json();
  });
}

function LoginPage({ onSuccess }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await loginUser({
      email,
      password,
    })
      .then((resp) => {
        // alert(`Welcome back, ${data.nickname}`);
        alert("Successfully logged in !");
        onSuccess(resp.data.token);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        alert("Email or Password is Invalid");
      });
  };

  return (
    // <div>Login Page</div>
    <div className="lg-login-wrapper">
      <h1>Log In</h1>
      <form onSubmit={handleSubmit}>
        <div class="lg-input-container">
          <p>Email: </p>
          <input
            type="text"
            name="email"
            onChange={(e) => setEmail(`${e.target.value}@illinois.edu`)}
            required
          />
          <label> @ illinois.edu</label>
        </div>
        <div class="lg-input-container">
          <p>Password: </p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div class="lg-button-container">
          <button className="blue-button" type="submit">
            Log In
          </button>
          <button className="white-button">
            <Link to="/password" className="button-link">
              Forgot password?
            </Link>
          </button>
          <button className="white-button">
            <Link to="/register" className="button-link">
              Register
            </Link>
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
