import { Link } from "react-router-dom";

const Navigation = (props) => {
  return (
    <div className="navbar">
      <h2 className="navbar-logo">
        <Link to="/" className="navbar-logo">
          UIUC Reviews
        </Link>
      </h2>
      <div className="navbar-padding"></div>
      <div className="navbar-buttons">
        {props.loggedIn ? (
          <>
            <Link to="/account" className="navbar-button">
              My Account
            </Link>
            <Link className="navbar-button" onClick={props.logoutOnClick}>
              Log Out
            </Link>
          </>
        ) : (
          <>
            <Link to="/login" className="navbar-button">
              Log In
            </Link>
            <Link to="/register" className="navbar-button">
              Register
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Navigation;
