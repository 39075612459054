// import ReviewsCard from "./ReviewsCard";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link, redirect } from "react-router-dom";
import "../style/CreateReviewPage.css";
import { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

const api = axios.create({
  baseURL: apiUrl,
});

async function createReview(review) {
  const authToken = localStorage.getItem("authToken");

  const { code, content, difficulty, workload, rating, year, term } = review;

  const parsedReview = {
    code,
    content,
    difficulty: parseFloat(difficulty),
    workload: parseFloat(workload),
    rating: parseFloat(rating),
    year: parseInt(year),
    term,
  };

  return fetch(`${apiUrl}/review`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parsedReview),
  }).then((response) => {
    console.log(response);
    if (response.status !== 201) {
      throw Error("Fail to create review");
    }
    return response.json();
  });
}

function CreateReviewPage(props) {
  const [content, setContent] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [workload, setWorkload] = useState("");
  const [rating, setRating] = useState("");
  const [year, setYear] = useState("");
  const [term, setTerm] = useState("fall");
  const [created, setCreated] = useState(false);
  let { code } = useParams();
  const [openAddReview, setopenAddReview] = useState(false);
  const [diffhover, setDiffHover] = useState(null);
  const [ratehover, setRateHover] = useState(null);

  const toggle = () => {
    setopenAddReview(!openAddReview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);

    await createReview({
      code,
      content,
      difficulty,
      workload,
      rating,
      year,
      term,
    }).then((res) => {
      console.log(res);
      setCreated(true);
      window.location.href=`/course/${code}`;
    });
  };

  if (created) {
    return redirect(`/course/${code}`);
  } else {
    return (
      <div>
        <button onClick={toggle} className="btn-openAddReview">
          Add Review
        </button>
        {openAddReview && (
          <div className="openAddReview">
            <div className="overlay">
              <div className="openAddReview-content">
                <h1>Submit your review</h1>
                <form onSubmit={handleSubmit}>
                  <div className="course-code">
                    <h2>{code}</h2>
                  </div>
                  <div className="rg-input-container">
                    <label>Difficulty: </label>
                    {[...Array(5)].map((star, i) => {
                      const ratingValue = i + 1;

                      return (
                        <label>
                          <input
                            type="radio"
                            name="difficulty"
                            value={ratingValue}
                            onClick={() => setDifficulty(ratingValue)}
                          />
                          <FaStar
                            className="star"
                            size={20}
                            color={
                              ratingValue <= (diffhover || difficulty)
                                ? "#FFD700"
                                : "#808080"
                            }
                            onMouseEnter={() => setDiffHover(ratingValue)}
                            onMouseLeave={() => setDiffHover(null)}
                          />
                        </label>
                      );
                    })}
                  </div>

                  <div className="rg-input-container">
                    <label>Workload: </label>
                    <input
                      type="text"
                      onChange={(e) => setWorkload(e.target.value)}
                      name="workload"
                      required
                    />
                    <label> hours/week</label>
                  </div>

                  <div className="rg-input-container">
                    <label>Rating: </label>
                    {[...Array(5)].map((star, i) => {
                      const ratingValue = i + 1;

                      return (
                        <label>
                          <input
                            type="radio"
                            name="rating"
                            value={ratingValue}
                            onClick={() => setRating(ratingValue)}
                          />
                          <FaStar
                            className="star"
                            size={20}
                            color={
                              ratingValue <= (ratehover || rating)
                                ? "#FFD700"
                                : "#808080"
                            }
                            onMouseEnter={() => setRateHover(ratingValue)}
                            onMouseLeave={() => setRateHover(null)}
                          />
                        </label>
                      );
                    })}
                  </div>

                  <div className="rg-input-container">
                    <label>Year: </label>
                    <input
                      type="text"
                      onChange={(e) => setYear(e.target.value)}
                      name="year"
                      required
                    />
                  </div>

                  <div className="rg-input-container">
                    <label>Term: </label>
                    <select
                      name="term"
                      id="term"
                      value={term}
                      onChange={(e) => setTerm(e.target.value)}
                    >
                      <option value="spring">Spring</option>
                      <option value="summer">Summer</option>
                      <option value="fall">Fall</option>
                      <option value="winter">Winter</option>
                    </select>
                  </div>

                  <div className="rg-input-container">
                    <p>Your review:</p>
                    <textarea
                      onChange={(e) => setContent(e.target.value)}
                      name="review"
                      required
                      value={content}
                      rows={5}
                      cols={40}
                    />
                  </div>

                  <div className="rg-button-container">
                    <button type="submit">Submit</button>
                  </div>
                </form>
                <button className="closeAddReview" onClick={toggle}>
                  cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CreateReviewPage;
