import React, { useState } from "react";
import "../style/RegisterPage.css";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

async function registerUser(credentials) {
  return fetch(`${apiUrl}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((response) => {
    if (response.status !== 201) {
      throw Error("Fail to find the register");
    }
    return response.json();
  });
}

function RegisterPage() {
  const [email, setEmail] = useState();
  const [nickname, setNickname] = useState();
  const [password, setPassword] = useState();
  const [registered, setRegistered] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await registerUser({
      email,
      nickname,
      password,
    })
      .then((res) => {
        console.log("res:" + res.data);
        alert(`Successfully registered, ${nickname}`);
        setRegistered(true);
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  const registerForm = (
    <div>
      <h1>Register</h1>
      <form onSubmit={handleSubmit}>
        <div className="rg-input-container">
          <p>Email: </p>
          <input
            type="text"
            name="email"
            onChange={(e) => setEmail(`${e.target.value}@illinois.edu`)}
            required
          />
          <label> @ illinois.edu</label>
        </div>
        <div className="rg-input-container">
          <p>Nickname: </p>
          <input
            type="text"
            name="nickname"
            onChange={(e) => setNickname(e.target.value)}
            required
          />
        </div>
        <div className="rg-input-container">
          <p>Password: </p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="rg-button-container">
          <button type="submit">Register</button>
        </div>
      </form>
    </div>
  );

  const activation = (
    <div>
      <h1>Confirm your email</h1>
      <p className="rg-acitivation-msg">
        Your account has been created. Please <br />
        follow the instructions emailed to you <br />
        to complete the registration process.
      </p>
    </div>
  );

  return (
    <div className="rg-register-wrapper">
      {registered ? activation : registerForm}
    </div>
  );
}

export default RegisterPage;
