// import ReviewsCard from "./ReviewsCard";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { heart } from "ionicons/icons";
import ReviewsCard from "./ReviewsCard";
import "../style/DetailsViewPage.css";
import { useState, useEffect } from "react";
import { averageGPA } from "../utils/gpa";
import { compareTermCodes, totalEnrollment, averageByKey } from "../utils/sort";
import CreateReviewPage from "./CreateReviewPage";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

const api = axios.create({
  baseURL: apiUrl,
});

// TODO: Enable only if registered user and check if already in favorites list
function favorite() {
  document.getElementById("favButton").classList.add("active");
}

function averageGpaAcrossSections(sections) {
  const runningCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  sections.forEach((section) => {
    for (let i = 0; i < section.gradeDistribution.length; i++) {
      runningCount[i] = runningCount[i] + section.gradeDistribution[i];
    }
  });
  return averageGPA(runningCount);
}

function DetailsViewPage(props) {
  // const [code, setCode] = useState("");
  const [course, setCourse] = useState({
    code: "Loading...",
    subject: "",
    number: "",
    title: "",
    description: "",
    creditHours: -2,
    sections: [],
    terms: {},
  });

  const [reviews, setReviews] = useState([]);
  let { code } = useParams();
  useEffect(() => {
    api
      .get(`/course/${code}`)
      .then(function (response) {
        if (response.data) {
          const courseData = response.data.data;
          const terms = {};
          courseData.sections.forEach((section) => {
            const term = `${section.year}-${section.term}`;
            if (section.year >= 2010) {
              if (!(term in terms)) {
                terms[term] = {
                  year: section.year,
                  term: section.term,
                  sections: [],
                };
              }
              terms[term].sections.push(section);
            }
          });
          courseData.terms = terms;
          console.log(courseData);
          console.log(Object.keys(courseData.terms).sort(compareTermCodes));
          setCourse(courseData);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    api
      .get(`/review?where={"code": "${code}"}`)
      .then((response) => {
        if (response.data) {
          const reviews = response.data.data;
          setReviews(reviews);
          console.log(reviews);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return course ? (
    <div className="detailsview-section">
      <div className="main-section">
        <h1>{course.code + " " + course.title}</h1>
        <div className="course-ratings">
          <p>
            Avg. GPA:{" "}
            {averageGpaAcrossSections(course.sections)
              ? averageGpaAcrossSections(course.sections).toFixed(2)
              : ""}
          </p>
          <p>
            Avg. Difficulty:{" "}
            {averageByKey(reviews, "difficulty") !== null
              ? averageByKey(reviews, "difficulty").toFixed(2)
              : ""}
          </p>
          <p>
            Avg. Workload:{" "}
            {averageByKey(reviews, "workload") !== null
              ? averageByKey(reviews, "workload").toFixed(2)
              : ""}
          </p>
          <p>
            Avg. Rating:{" "}
            {averageByKey(reviews, "rating") !== null
              ? averageByKey(reviews, "rating").toFixed(2)
              : ""}
          </p>
        </div>
        <div className="course-info">
          <p>{course.description}</p>
          {course.creditHours === -2 ? (
            <></>
          ) : (
            <p>
              Credit: {course.creditHours} hour
              {course.creditHours === 1 ? "" : "s"}
            </p>
          )}
        </div>
        <div className="section-info">
          <div className="section-headers">
            <div>
              <h2>Term</h2>
            </div>
            <div>
              <h2>Number of sections</h2>
            </div>
            <div>
              <h2>Enrollment</h2>
            </div>
            <div>
              <h2>Average GPA</h2>
            </div>
          </div>
          {Object.keys(course.terms)
            .sort(compareTermCodes)
            .reverse()
            .map((termCode) => (
              <div className="section-row" key={`${termCode}`}>
                <div>
                  {course.terms[termCode].term[0].toUpperCase() +
                    course.terms[termCode].term.slice(1)}{" "}
                  {course.terms[termCode].year}{" "}
                </div>
                <div>{course.terms[termCode].sections.length}</div>
                <div>
                  {totalEnrollment(course.terms[termCode].sections)
                    ? totalEnrollment(course.terms[termCode].sections)
                    : ""}
                </div>
                <div>
                  {averageGpaAcrossSections(course.terms[termCode].sections)
                    ? averageGpaAcrossSections(
                        course.terms[termCode].sections
                      ).toFixed(2)
                    : ""}
                </div>
              </div>
            ))}
        </div>

        {props.loggedIn ? (
          <CreateReviewPage className="button" code={code}>
            Add Review
          </CreateReviewPage>
        ) : (
          <></>
        )}

        {reviews ? <ReviewsCard code={code} reviews={reviews} /> : <></>}
      </div>
      <div className="side-section">
        {/* <div className="favorite">
          <IonIcon id="favButton" icon={heart} onClick={favorite}></IonIcon>
          <p>Add to Favorites</p>
        </div> */}
        {/* <div className="related-info">
          <h2>Related Links</h2>
          <ul className="related-links">
            <li>
              <Link>Syllabus</Link>
            </li>
            <li>
              <Link>Web Page</Link>
            </li>
            <li>
              <Link>Academic Pages</Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default DetailsViewPage;
