import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const MobileNavigation = (props) => {
  return (
    <>
      {props.isMenuOpen && (
        <div className="navbar-buttons">
          {props.loggedIn ? (
            <>
              <Link
                to="/account"
                className="navbar-button"
                onClick={props.closeMenu}
              >
                My Account
              </Link>
              <Link
                className="navbar-button"
                onClick={() => {
                  props.logoutOnClick();
                  props.closeMenu();
                }}
              >
                Log Out
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="navbar-button"
                onClick={props.closeMenu}
              >
                Log In
              </Link>
              <Link
                to="/register"
                className="navbar-button"
                onClick={props.closeMenu}
              >
                Register
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MobileNavigation;
