import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "./App.css";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import Navigation from "./components/Navigation";
import MobileNavigation from "./components/MobileNavigation";
import DetailsViewPage from "./components/DetailsViewPage";
import ListViewPage from "./components/ListViewPage";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import PasswordPage from "./components/PasswordPage";
import ConfirmResetPage from "./components/ConfirmResetPage";
import AcctInfoPage from "./components/AcctInfoPage";
import ActivationPage from "./components/ActivationPage";
import CreateReviewPage from "./components/CreateReviewPage";

function App() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuIcon = (
    <FiMenu
      className="menu"
      size="50px"
      onClick={() => setMenuOpen(!isMenuOpen)}
    />
  );
  const closeMenuIcon = (
    <IoClose
      className="menu"
      size="50px"
      onClick={() => setMenuOpen(!isMenuOpen)}
    />
  );
  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken !== null) {
      setLoggedIn(true);
    }
  }, [loggedIn]);

  const loginOnSuccess = (token) => {
    localStorage.setItem("authToken", token);
    setLoggedIn(true);
  };

  const logoutOnClick = () => {
    localStorage.removeItem("authToken");
    setLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="App">
      <header>
        <Navigation loggedIn={loggedIn} logoutOnClick={logoutOnClick} />
        <div className="mobileNavigation">
          <h2 className="navbar-logo">
            <Link to="/" className="navbar-logo">
              UIUC Reviews
            </Link>
          </h2>
          {isMenuOpen ? closeMenuIcon : menuIcon}
          <MobileNavigation
            loggedIn={loggedIn}
            isMenuOpen={isMenuOpen}
            logoutOnClick={logoutOnClick}
            closeMenu={closeMenu}
          />
        </div>
      </header>
      <div className="main-wrapper">
        <Routes>
          <Route
            path="/login"
            element={<LoginPage onSuccess={loginOnSuccess} />}
          />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/account"
            element={<AcctInfoPage logout={logoutOnClick} />}
          />
          <Route path="/password" element={<PasswordPage />} />
          <Route path="/passwordreset" element={<ConfirmResetPage />} />
          <Route path="/" element={<ListViewPage />} />
          <Route
            path="/course/:code"
            element={<DetailsViewPage loggedIn={loggedIn} />}
          />
          <Route
            path="/activate/:email/:confirmationCode"
            element={<ActivationPage />}
          />
          <Route path="/activate" element={<ActivationPage />} />
          <Route
            path="/review/:code"
            element={<CreateReviewPage loggedIn={loggedIn} />}
          />
        </Routes>
      </div>
      {/* <footer>
          <div clss="info">
            <div>
              <p>© 2022 Team: Tacos Architects</p>
            </div>
          </div>
        </footer> */}
    </div>
  );
}

export default App;
