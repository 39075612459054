import { Link } from "react-router-dom";

function ListViewCard(props) {
  return (
    <Link to={`/course/${props.code}`} className="listview-card">
      <div className="course">
        {props.course}{" "}
        {props.gpa ? <div className="gpa">Avg GPA: {props.gpa}</div> : <></>}
      </div>
      <div className="title">{props.title}</div>
      <div className="description">{props.description}</div>
    </Link>
  );
}

export default ListViewCard;
