import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../style/AcctInfoPage.css";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

async function getUser() {
  const authToken = localStorage.getItem("authToken");
  return fetch(`${apiUrl}/user`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status !== 200) {
      throw Error("Fail to load user account");
    }
    return response.json();
  });
}

async function getReviews() {
  const authToken = localStorage.getItem("authToken");
  return fetch(`${apiUrl}/user/reviews`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status !== 200) {
      throw Error("Fail to load reviews");
    }
    return response.json();
  });
}

async function getCourseTitle(code) {
  return fetch(`${apiUrl}/course/${code}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status !== 200) {
      throw Error("Fail to load this course");
    }
    return response.json();
  });
}

async function deleteUser() {
  const authToken = localStorage.getItem("authToken");
  return fetch(`${apiUrl}/user`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status !== 201) {
      throw Error("Fail to delete your account");
    }
    return response.json();
  });
}

async function deleteReview(id) {
  const authToken = localStorage.getItem("authToken");
  return fetch(`${apiUrl}/review/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status !== 201) {
      throw Error("Fail to delete this review");
    }
    return response.json();
  });
}

function AcctInfoPage({ logout }) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [reviews, setReviews] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const p1 = getUser()
      .then((resp) => {
        setUserData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const p2 = getReviews()
      .then(async (resp) => {
        const arr = resp.data;
        let tempList = [];
        console.log("getReviews:" + arr[0]);
        await Promise.all(
          arr.map(async (item) => {
            console.log("course code:" + item.code);
            await getCourseTitle(item.code)
              .then((res) => {
                return res.data.title;
              })
              .then((data) => {
                item = { ...item, title: data };
                tempList.push(item);
                console.log("templist:" + tempList.length);
              })
              .catch((err) => console.log(err));
          })
        );
        setReviews(tempList);
      })
      .catch((err) => {
        console.log(err);
      });

    Promise.all([p1, p2]).then(() => {
      console.log("loading:" + loading);
      setLoading(false);
    });
  }, [loading]);

  const onDeleteUser = () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      // Go ahead to elete!
      console.log("Yes, delete user account");
      deleteUser()
        .then((resp) => {
          alert("Successfully deleted user account");
          logout();
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Do nothing!
      console.log("No, Cancel");
    }
  };

  const onDeleteReview = (id) => {
    if (window.confirm("Are you sure you want to delete this review?")) {
      // Go ahead to elete!
      console.log("Yes, delete review");
      deleteReview(id)
        .then((resp) => {
          alert("Successfully deleted review");
          let newList = reviews.filter((item) => item._id !== id);
          setReviews(newList);
          console.log(reviews);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Do nothing!
      console.log("No, Cancel");
    }
  };

  const reviewList = (list) => {
    const listItems = list.map((item) => (
      <li key={item._id} onClick={() => console.log(item.code)}>
        <table className="reviewTable">
          <tbody>
            <tr>
              <td>
                <Link to="/account" className="ai-button edit-button">
                  Edit
                </Link>
                <button
                  key={item._id}
                  id="del-button"
                  className="ai-button"
                  onClick={() => {
                    onDeleteReview(item._id);
                  }}
                >
                  Delete
                </button>
              </td>
              <td colspan="6">
                {/* <span>{item.date}</span>  */}
                <Link to={`/course/${item.code}`}>
                  {item.code} - {item.title}
                </Link>
              </td>
            </tr>
            <tr className="msg-style">
              <td></td>
              <td>Rating:</td>
              <td>{item.rating}</td>
              <td>Workload:</td>
              <td>{item.workload}</td>
              <td>Difficulty:</td>
              <td>{item.difficulty}</td>
            </tr>
            <tr className="msg-style">
              <td></td>
              <td colspan="6">{item.content}</td>
            </tr>
          </tbody>
        </table>
        <hr />
      </li>
    ));

    return <ul>{listItems}</ul>;
  };

  // TODO: fav function to be implemented later
  // const favList = (list) => {
  //     const listItems = list.map((item) =>
  //       <li key={item._id} onClick={() => (console.log(item.code))}>
  //         <Link to={`/course/${item.code}`}>{item.code} - {item.title}</Link>
  //       </li>
  //     );

  //     return (
  //       <ul>
  //         {listItems}
  //       </ul>
  //     );
  //   }

  return (
    <>
      {loading ? (
        <p>Page Loading ...</p>
      ) : (
        <div className="acctInfo-wrapper">
          <div className="main-section">
            <div className="info-section">
              <h2>Account Info</h2>
              <table className="reviewTable">
                <tbody>
                  <tr>
                    <td className="tb-item">Email:</td>
                    <td className="tb-val">{userData.email}</td>
                  </tr>
                  <tr>
                    <td className="tb-item">Nickename:</td>
                    <td className="tb-val">{userData.nickname}</td>
                  </tr>
                  {/* <tr>
                          <td className="tb-item">Department:</td>
                          <td className="tb-val">CS</td>
                      </tr> */}
                </tbody>
              </table>
              <div className="button-container">
                <Link to="/password" className="ai-button">
                  Reset password
                </Link>
                <button
                  id="del-button"
                  className="ai-button"
                  onClick={onDeleteUser}
                >
                  Delete Account
                </button>
              </div>
            </div>
            <div className="review-section">
              <h2>My reviews</h2>
              {reviewList(reviews)}
            </div>
          </div>
          {/* <div className="fav-section">
            <h2>Favorite Courses</h2>
            {favList(reviews)}
        </div> */}
        </div>
      )}
    </>
  );
}

export default AcctInfoPage;
