import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../style/PasswordPage.css";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

async function changePassword(newPassword) {
  const authToken = localStorage.getItem("authToken");
  return fetch(`${apiUrl}/user/changepassword`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPassword),
  }).then((response) => {
    if (response.status !== 200) {
      throw Error("Fail to change password");
    }
    return response.json();
  });
}

async function resetPassword(email) {
  return fetch(`${apiUrl}/user/resetpassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  }).then((response) => {
    if (response.status !== 201) {
      throw Error("Fail to find this account");
    }
    return response.json();
  });
}

function PasswordPage() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState();
  const [newPassword, setNewPassword] = useState();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken !== null) {
      setLoggedIn(true);
    }
  }, [loggedIn]);

  const handleChange = async (e) => {
    e.preventDefault();

    await changePassword({
      newPassword,
    })
      .then((res) => {
        alert("Successfully change password !");
        navigate("/account");
      })
      .catch((err) => {
        console.log(err);
        alert("Confirmation Code is not right, please enter again");
      });
  };

  const handleReset = async (e) => {
    e.preventDefault();

    await resetPassword({ email })
      .then((res) => {
        console.log("res:" + res.message);
        // alert(`Account found !`);
        navigate("/passwordreset");
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  };

  const changePsw = (
    <div className="change-wrapper">
      <h1>Change Password</h1>
      <p class="reset-msg">Please input your new password</p>
      <form onSubmit={handleChange}>
        <div className="input-container">
          <div className="input-item">
            <label>New Password: </label>
            <input
              type="password"
              name="newPassword"
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div class="button-container">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );

  const resetPsw = (
    <div className="reset-wrapper">
      <h1>Reset Password</h1>
      <form onSubmit={handleReset}>
        <div class="input-container">
          <label className="input-title">Email: </label>
          <input
            type="text"
            name="email"
            onChange={(e) => setEmail(`${e.target.value}@illinois.edu`)}
            required
          />
          <label> @ illinois.edu</label>
        </div>
        <div class="button-container">
          <button type="submit">Reset password</button>
        </div>
      </form>
    </div>
  );

  return (
    <div className="password-wrapper">{loggedIn ? changePsw : resetPsw}</div>
  );
}

export default PasswordPage;
