export function averageGPA(gradeDistribution) {
  const GPA_MAP = [
    4, 4, 3.67, 3.33, 3, 2.67, 2.33, 2, 1.67, 1.33, 1, 0.67, 0, 0,
  ];

  const gpaList = [];

  for (let i = 0; i < GPA_MAP.length; i++) {
    gpaList[i] = gradeDistribution[i] * GPA_MAP[i];
  }

  return (
    gpaList.reduce((a, b) => a + b, 0) /
    gradeDistribution.reduce((a, b) => a + b, 0)
  );
}
