import "../style/ListViewPage.css";
import React, { useState } from "react";

function ListViewAccordion(props) {
  const [collapsed, setCollapsed] = useState(true);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="listview-accordion">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.css"
      ></link>

      <button onClick={() => setCollapsed(!collapsed)}>
        <h3>
          {collapsed ? "+ " : "- "} {props.label}
        </h3>
      </button>

      <div className={collapsed ? "hidden" : ""}>
        <div className={expanded ? "filters expanded" : "filters limited"}>
          <form>
            {props.optionsArr.length ? (
              props.optionsArr.map((str) => {
                return (
                  <div key={`filter-${str}`}>
                        <input type="checkbox" value={str} checked={props.currentFilters.includes(String(str))} onChange={
                            (e) => {
                                console.log("current " + props.currentFilters);
                                console.log("target " + e.target.value);
                                if (props.currentFilters.length > 0 && props.currentFilters.includes(e.target.value)) {
                                    console.log("already checked");
                                    props.setFilters(props.currentFilters.filter((elem) => {
                                        return (elem === e.target.value)
                                            ? false
                                            : true
                                    }));
                                }
                                else {
                                    console.log("checked now");
                                    let filtersCopy = [];
                                    props.currentFilters.forEach((filt) => {
                                        filtersCopy.push(filt);
                                    });
                                    filtersCopy.push(e.target.value);
                                    props.setFilters(filtersCopy);
                                }
                            }}/>
                    <label> {str}</label>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </form>
        </div>

        <button
          onClick={() => setExpanded(!expanded)}
          className={collapsed || props.optionsArr.length <= 6 ? "hidden" : ""}
        >
          <h4>
            <i
              className={expanded ? "fas fa-angle-up" : "fas fa-angle-down"}
            ></i>
            {expanded ? " See Less" : " See More"}
          </h4>
        </button>
      </div>
    </div>
  );
}

export default ListViewAccordion;
