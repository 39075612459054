import axios from "axios";
import ListViewCard from "./ListViewCard";
import ListViewAccordion from "./ListViewAccordion";
import "../style/ListViewPage.css";
import { useState, useEffect } from "react";
import { averageGPA } from "../utils/gpa";

let apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://api.uiucreviews.com";

const api = axios.create({
  baseURL: apiUrl,
});

function averageGpaAcrossSections(sections) {
  const runningCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  sections.forEach((section) => {
    for (let i = 0; i < section.gradeDistribution.length; i++) {
      runningCount[i] = runningCount[i] + section.gradeDistribution[i];
    }
  });
  return averageGPA(runningCount);
}

function ListViewPage() {
  const [subjects, setSubjects] = useState([]);
  const [levels, setLevels] = useState([]);
  const [terms, setTerms] = useState([]);
  const [types, setTypes] = useState([]);
  const [credits, setCredits] = useState([]);

  const [subjectFilters, setSubjectFilters] = useState([]);
  const [levelFilters, setLevelFilters] = useState([]);
  const [termFilters, setTermFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [creditFilters, setCreditFilters] = useState([]);

  const [query, setQuery] = useState(""); //for search bar
  const [data, setData] = useState([]); //array of course objects
  const [cardDisplay, setCardDisplay] = useState([]); //for checkboxes beneath search bar

  useEffect(() => {
    //update data when query changes

    if (query == null || query.length === 0) {
      //no query: get all courses
      console.log("No query get");
      api
        .get("/course")
        .then(function (response) {
          console.log("we have a response!");
          let tempData;
          if (response.data) {
              tempData = response.data.data;
              setData(tempData);
              
          }
        })
        .catch((error) => {
          console.error(error);
        });
      api
        .get("/course/filters") //get all filter options without having to load all courses data
        .then(function (response) {
          console.log("we have a response!");
          let tempData;
          if (response.data) {
            tempData = response.data.data;
            console.log(tempData.subjects);
            setSubjects(tempData.subjects);
            setLevels(tempData.numbers);
            setCredits(
              tempData.creditHours.filter((hrs) => {
                if (hrs >= 0) {
                  return true;
                }
                return false;
              })
            );
            setTerms(
              tempData.yearTerms.map((item) => {
                let split = item.split("-");
                return (
                  split[1][0].toUpperCase() + split[1].slice(1) + " " + split[0]
                );
              })
            );
            setTypes(
              tempData.deliveryTypes.filter((type) => {
                if (type != null && type.length > 0) {
                  return true;
                }
                return false;
              })
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      //TODO: make this get case-insensitive
      api
        .get(`/course?where={"title": {"$regex": "${query}"}}`)
        .then(function (response) {
          console.log("we have a response to query string! " + query);
          let tempData;
          if (response.data) {
            console.log(response.data);
              tempData = response.data.data;
              setData(tempData);
              

            setSubjects([
              ...new Set(
                tempData.map((item) => {
                  return item.subject;
                })
              ),
            ]);

            setLevels([
              ...new Set(
                tempData.map((item) => {
                  return Math.floor(item.number / 100) * 100;
                })
              ),
            ]);

            setCredits([
              ...new Set(
                tempData.map((item) => {
                  return String(item.creditHours);
                })
              ),
            ]);

            setTerms([
              ...new Set(
                ...tempData.map((item) => {
                  return [
                    ...new Set(
                      item.sections.map((section) => {
                        //capitalize first letter, combine term and year (ex: "Spring 2022")
                        return (
                          section.term[0].toUpperCase() +
                          section.term.slice(1) +
                          " " +
                          section.year
                        );
                      })
                    ),
                  ];
                })
              ),
            ]);

            setTypes([
              ...new Set(
                ...tempData.map((item) => {
                  return [
                    ...new Set(
                      item.sections
                        .filter((section) => {
                          if (
                            section.deliveryType === "" ||
                            section.deliveryType === undefined
                          ) {
                            return false; //skip if empty delivery type
                          }
                          return true;
                        })
                        .map((section) => {
                          return section.deliveryType;
                        })
                    ),
                  ];
                })
              ),
            ]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }

      setSubjectFilters([]);
      setCreditFilters([]);
      setLevelFilters([]);
      setTermFilters([]);
      setTypeFilters([]);
  }, [query]);

  //TODO: get filtered data when filter arrays are updated
    useEffect(() => { //filters data when any of the filter arrays are updated

        console.log("subject: " + subjectFilters);
        console.log("credit: " + creditFilters);
        console.log("level: " + levelFilters);
        console.log("term: " + termFilters);
        console.log("type: " + typeFilters);

      let queryStr = "";
          if (query != null && query.length > 0) {
              queryStr += `"title" : {"$regex": "${query}"}`
          }
          if (subjectFilters.length > 0) {
              if (queryStr.length > 0) {
                  queryStr += ",";
              }
              queryStr += `"subject" : { "$in" : [`;
              for (let i = 0; i < subjectFilters.length - 1; i++) {
                  queryStr += `"${subjectFilters[i]}",`;
              }
              queryStr += `"${subjectFilters[subjectFilters.length - 1]}"] }`;
          }
          if (creditFilters.length > 0) {
              if (queryStr.length > 0) {
                  queryStr += ",";
              }
              queryStr += `"creditHours" : { "$in" : [`;
              for (let i = 0; i < creditFilters.length - 1; i++) {
                  queryStr += `${creditFilters[i]},`;
              }
              queryStr += `${creditFilters[creditFilters.length - 1]}] }`;

        }
        if (levelFilters.length > 0) {
            if (queryStr.length > 0) {
                queryStr += ",";
            }
            if (levelFilters.length > 1) {
                queryStr += `"$or" : ["$and": [{ "number": { "$gte" : ${levelFilters[0]} }}, { "number": { "$lt" : ${(Number(levelFilters[0]) + 100)}}}]} `;
                for (let i = 1; i < levelFilters.length; i++) {
                    queryStr += `, { "$and": [{"$number": { "$gte" : ${levelFilters[i]} }}, {"$number": { "$lt" : ${(Number(levelFilters[0]) + 100)}}}] } `;
                }
                queryStr += `]`;
            }
            else {
                queryStr += `"$and": [{"number": { "$gte" : ${levelFilters[0]} }}, {"number": { "$lt" : ${(Number(levelFilters[0]) + 100)}}}]`;
            }
            
        }
/*        if (levelFilters.length > 0 || termFilters.length > 0 || typeFilters.length > 0) {
            if (queryStr.length > 0) {
                queryStr += ",";
            }
            queryStr += `"creditHours" : { "$in" : [`;
        }*/
        console.log(queryStr);
       
      api
          .get( (queryStr.length > 0) ? `/course?where={${queryStr}}` : "/course")
          .then(function (response) {
              console.log("we have a response! filtered");
              let tempData;
              if (response.data) {
                  console.log(response.data.data);
                  tempData = response.data.data;
                  setData(tempData);
              }
          })
          .catch((error) => {
              console.error(error);
          });
  }, [subjectFilters, levelFilters, creditFilters, termFilters, typeFilters]);

  //TODO: add to each ListViewCard params for information from reviews (rating, hours per week, avg GPA)
  return (
    <div className="listview-wrapper">
      <div className="sidebar">
        <ListViewAccordion
                  label="Subject"
                  setFilters={setSubjectFilters}
                  optionsArr={subjects}
                  currentFilters={subjectFilters}
        />

        <ListViewAccordion
          label="Course Level"
          setFilters={setLevelFilters}
                  optionsArr={levels}
                  currentFilters={levelFilters}
        />

        <ListViewAccordion
          label="Credit Hours"
          setFilters={setCreditFilters}
                  optionsArr={credits}
                  currentFilters={creditFilters}
        />

        <ListViewAccordion
          label="Term"
          setFilters={setTermFilters}
                  optionsArr={terms}
                  currentFilters={termFilters}
        />

        <ListViewAccordion
          label="Delivery Method"
          setFilters={setTypeFilters}
                  optionsArr={types}
                  currentFilters={typeFilters}
        />
      </div>

      <div className="listview-area">
        <div className="search-area">
          <input
            id="search-bar"
            type="text"
            autoComplete="off"
            placeholder="Search"
          ></input>
          <button
            type="submit"
            onClick={(e) => {
              setQuery(document.getElementById("search-bar").value);
            }}
          >
            <i className="fa fa-search"></i>
          </button>
        </div>

        <div className="grid-area">
            {data.map((item) => {
            return (
                <ListViewCard
                course={item.subject + " " + item.number}
                title={item.title}
                description={item.description}
                code={item.code}
                gpa={
                  averageGpaAcrossSections(item.sections)
                    ? averageGpaAcrossSections(item.sections).toFixed(2)
                    : ""
                }
                key={item.code}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ListViewPage;
